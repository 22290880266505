import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    Container,
    Typography,
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    Grid,
    Button,
    darken,
    useMediaQuery, TextField, InputAdornment, IconButton,
} from "@mui/material";
import Image from "../components/common/Image";
import { useTranslation } from "react-i18next";
import AccordionComponent from "../components/common/Accordion";
import { openModal, closeModal } from "../features/slices/modalSlice";
import * as Constant from "../features/constant";
import CopiedTooltip from "../components/common/CopiedTooltip";

const Referral = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const boxStyle = {
        width: '200px',
        height: '80px',
        backgroundImage: 'url(/assets/img/yellow_big.png)',
        backgroundSize: '100% 100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 1,
        textAlign: 'center',
    };

    const boxReward = {
        width: '100%',
        height: '80px',
        backgroundColor: 'lightgrey',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
        margin: 1,
        textAlign: 'left',
        borderRadius: '10px',
    };

    const textContainer = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px'
    };

    return (
        <Container>
            <Typography fontWeight="bold" color="white" paddingTop={"70px"} textAlign={"center"}>
                Invite Friends
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', margin: 1}}>
                <Box sx={{ display: 'flex', justifyContent: 'left', margin: 1 }}>
                    <Box sx={boxStyle}>
                        <Typography sx={{marginTop: '10px', fontSize: '12px', color: '#FFFFFF', fontWeight:'bold'}}>Yesterday's Referral Bonus</Typography>
                        <Typography sx={{color: '#FFFFFF', fontWeight:'bold'}}>BDT 0.00</Typography>
                    </Box>
                    <Box sx={boxStyle}>
                        <Typography sx={{marginTop: '10px', fontSize: '12px', color: '#FFFFFF', fontWeight:'bold'}}>Yesterday's Team Referral Commission</Typography>
                        <Typography sx={{color: '#FFFFFF', fontWeight:'bold'}}>BDT 0.00</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'left', margin: 1 }}>
                <Box sx={boxStyle}>
                        <Typography sx={{marginTop: '20px', fontSize: '12px', color: '#FFFFFF', fontWeight:'bold'}}>Yesterday's Register</Typography>
                        <Typography sx={{color: '#FFFFFF', fontWeight:'bold'}}>0</Typography>
                    </Box>
                    <Box sx={boxStyle}>
                        <Typography sx={{marginTop: '10px', fontSize: '12px', color: '#FFFFFF', fontWeight:'bold'}}>Yesterday's Team Deposit</Typography>
                        <Typography sx={{color: '#FFFFFF', fontWeight:'bold'}}>BDT 0.00</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'left', margin: 1 }}>
                    <Box sx={boxStyle}>
                        <Typography sx={{marginTop: '10px', fontSize: '12px', color: '#FFFFFF', fontWeight:'bold'}}>Yesterday's Active Member</Typography>
                        <Typography sx={{color: '#FFFFFF', fontWeight:'bold'}}>0</Typography>
                    </Box>
                    <Box sx={boxStyle}>
                        <Typography sx={{marginTop: '10px', fontSize: '12px', color: '#FFFFFF', fontWeight:'bold'}}>Yesterday's Team Valid Bet</Typography>
                        <Typography sx={{color: '#FFFFFF', fontWeight:'bold'}}>BDT 0.00</Typography>
                    </Box>
                </Box>
            </Box>
            <Typography color="white" textAlign={"center"}>
                Share to your friends
            </Typography>
            <br/>
            <Typography fontWeight="bold" color="grey" textAlign={"center"}>
                Invitation URL
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TextField
                // label={t("walletcard.myaccountpage.referrallink")}
                // value={shareLink}
                InputLabelProps={{shrink: true}}
                disabled
                type="text"
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                sx={{
                                    "&.MuiButtonBase-root:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <CopiedTooltip text={"shareLink"}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{width:'326px'}}
            />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <img src="/assets/img/facebook.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
                <img src="/assets/img/telegram.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
                <img src="/assets/img/twitter.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
                <img src="/assets/img/whatsapp.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
            </div>
            </Box>
            <Typography fontWeight="bold" color="white" paddingTop={"20px"} textAlign={"center"} fontSize={"24px"}>
            Rewards released so far
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', margin: 1, paddingBottom:'50px'}}>
                <Box sx={{ justifyContent: 'left', margin: 0.5 }}>
                    <Box sx={boxReward}>
                        <Box component="img" src="/assets/img/reward_1.png" sx={{ width: 60, height: 60, padding:'10px', margin:'10px' }} />
                        <Box sx={textContainer}>
                        <Typography sx={{fontSize: '14px', color: '#000000', fontWeight:'bold', paddingTop: '5px'}}>Referral Bonus</Typography>
                        <Typography sx={{fontSize: '20px', color: theme.palette.secondary.main, fontWeight:'bold'}}>BDT 68,600.00</Typography>
                        <Typography sx={{fontSize: '12px', color: '#000000'}}>1,372 people</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ justifyContent: 'left', margin: 0.5 }}>
                    <Box sx={boxReward}>
                        <Box component="img" src="/assets/img/reward_2.png" sx={{ width: 60, height: 60, padding:'10px', margin:'10px' }} />
                        <Box sx={textContainer}>
                            <Typography sx={{fontSize: '14px', color: '#000000', fontWeight:'bold', paddingTop: '5px'}}>Achievement Rewards</Typography>
                            <Typography sx={{fontSize: '20px', color: theme.palette.secondary.main, fontWeight:'bold'}}>BDT 372,079.50</Typography>
                            <Typography sx={{fontSize: '12px', color: '#000000'}}>290,372 people</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ justifyContent: 'left', margin: 0.5 }}>
                    <Box sx={boxReward}>
                        <Box component="img" src="/assets/img/reward_3.png" sx={{ width: 60, height: 60, padding:'10px', margin:'10px' }} />
                        <Box sx={textContainer}>
                            <Typography sx={{fontSize: '14px', color: '#000000', fontWeight:'bold', paddingTop: '5px'}}>Achievement Rewards</Typography>
                            <Typography sx={{fontSize: '20px', color: theme.palette.secondary.main, fontWeight:'bold'}}>BDT 155,352.99</Typography>
                            <Typography sx={{fontSize: '12px', color: '#000000'}}>11,822 people</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
export default Referral;
