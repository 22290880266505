import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Container,
  darken,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Image from "../components/common/Image";
import { useTranslation } from "react-i18next";
import { useGetLanguagesQuery } from "../features/api/publicApiSlice";
import * as Constant from "../features/constant";

const Language = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleLanguageChange = () => {
      localStorage.setItem("language", i18n.language);

      if (process.env.REACT_APP_LANG_PATH_PARAM) {
        // let strArr = window.location.pathname.replace("/","").split("/");
        // let pathname = window.location.pathname;
        // let langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY][i18n.language];

        // if (strArr.length > 1) {
        //   window.location.href = "/" + langParam + "/" + pathname.replace("/" + strArr[0] + "/", "");
        // }
        // else {
        //   window.location.href = "/" + langParam + pathname.replace("/" + strArr[0], "");
        // }
        navigate(Constant.getPath("/"));
      }
      else {
        window.location.reload();
      }
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isLoading: isLanguagesLoading,
    isSuccess: isLanguagesSuccess,
  } = useGetLanguagesQuery({ prefetch: true });

  return (
    <>
      {mobileView && (
        <>
          <Grid
            container
            justify="center"
            py={1}
            backgroundColor={darken(theme.palette.background.paper, 0.4)}
          >
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("language")}
              </Typography>
            </Grid>
          </Grid>
          <Container
            mt={0}
            sx={{
              minHeight: "100vh",
              backgroundColor: "black",
            }}
          >
            <Box display="flex" flexDirection="column" px={2} pt={5}>
              {/* <Typography color="white">{t("translation.country")}</Typography> */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={1}
              >
                <Image
                  width="40px"
                  height="40px"
                  src={process.env.REACT_APP_BD_ICON}
                />
                <Box display="flex" pl={1}>
                  {languages?.result?.map((language) => (
                    <Typography
                      px={1}
                      sx={{
                        cursor: "pointer",
                        color:
                          i18n.language.toUpperCase() === language.code
                            ? "#1E90FF"
                            : "white",
                      }}
                      onClick={() => {
                        i18n.changeLanguage(language.code?.toLowerCase());
                      }}
                    >
                      {language?.label}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default Language;
