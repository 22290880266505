import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  Box,
  Divider,
  List,
  ListItemButton,
  IconButton,
  useTheme,
  Typography,
  ListItemText,
} from "@mui/material";
import { Menu, Language, Call, Diversity3 } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../icons/header/HomeIcon";
import PromotionIcon from "../../icons/header/PromotionIcon";
import VipIcon from "../../icons/header/VipIcon";
import Image from "./Image";
import UserCard from "./UserCard";
import * as Constant from "../../features/constant";

const drawerWidth = 220;

const Drawer = ({ user, window ,isBD}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const container =
    // window !== undefined ? () => window().document.body : undefined;
    typeof window !== "undefined" ? window.document.body : undefined;
    const [drawerOpen, setDrawerOpen] = useState(true); // Set initial state to true
  const drawerItems = [
    {
      label: t("drawer.home"),
      code: "home",
      icon: (
        <HomeIcon width={25} height={25} color={theme.palette.primary.main} />
      ),
    },
    {
      label: t("drawer.referandearn"),
      code: "affiliate",
      icon: (
        <Diversity3
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      ),
    },
    {
      label: t("drawer.promotion"),
      code: "promotion",
      icon: (
        <PromotionIcon
          width={25}
          height={25}
          color={theme.palette.primary.main}
        />
      ),
    },
    {
      label: t("drawer.vip"),
      code: "vip",
      icon: (
        <VipIcon width={25} height={25} color={theme.palette.primary.main} />
      ),
    }];

    Constant.getCustomMenuItems().map(item => {
      drawerItems.push({
        isGameCategory: true,
        label: t('gamecategory.' + item.code.toLowerCase().replaceAll("_", "").replaceAll("-", "")),
        code: item.code.replaceAll("_", "-"),
        icon: (
          <img src={item.imageLogoOnly} alt={item.name} style={{ width: '25px', height: '25px' }} />
        ),
      });
    });

    drawerItems.push({
      label: t("drawer.affiliate"),
      code: "affiliate",
      icon: (
        <Diversity3
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      ),
    });
    drawerItems.push({
      label: t("drawer.contactus"),
      code: "contactus",
      icon: (
        <Call
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      ),
    });
    drawerItems.push({
      label: t("drawer.language"),
      code: "language",
      icon: (
        <Language
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      ),
    });
    // {
    //   label: "Affiliate",
    //   icon: <Diversity3 sx={{ display: "block", width: "100%", mb: 1 }} />,
    // },
  // ];

  const handleDrawerToggle = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
    console.log("Drawer state:", drawerOpen);

  };

  // const handleGameDrawerToggle = () => {
  //   setGameDrawerOpen(!gameDrawerOpen);
  // };

  const drawerContent = (
    <>
      <Box>
        <UserCard
          isDrawer={true}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          user={user}
        />
      </Box>
      <Divider
        sx={{ bgcolor: theme.palette.primary.main, height: "5px" }}
      />
      <List
        sx={{
          justifyContent: "center",
          alignSelf: "center",
          px: 3,
          paddingLeft: "0px"
        }}
      >
        {drawerItems.map((item, index) => {
          const isExternalLink = item.code?.toLowerCase() === "affiliate"; 

          return isExternalLink ? (
            // <a
            //   key={index}
            //   href={"https://www.aff.winbagh8.com/"} 
            //   target="_blank"
            //   rel="noopener noreferrer"
            //   style={{ textDecoration: "none", color: "inherit" }} 
            // >
              <ListItemButton
              onClick={(event) => {
                navigate(
                  item?.code?.toLowerCase() === "affiliate"
                    ? Constant.getPath("/")
                    : Constant.getPath("/" + item?.code?.toLowerCase().replace(/\s+/g, ""))
                );
                handleDrawerToggle(event);
              }}
                sx={{
                  width: "100%",
                  mb: 0,
                }}
                disableRipple
              >
                <>
                  {item.icon}
                  <ListItemText
                    sx={{
                      ml: 1,
                      fontWeight: 600,
                      lineHeight: 0,
                      fontSize: "14px",
                      color: "white",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    disableTypography
                  >
                    {item.label}
                  </ListItemText>
                </>
              </ListItemButton>
            // </a>
          ) : (
            <ListItemButton
              key={index}
              onClick={(event) => {
                navigate(
                  item?.code?.toLowerCase() === "home"
                    ? Constant.getPath("/")
                    : Constant.getPath("/" + item?.code?.toLowerCase().replace(/\s+/g, ""))
                );
                handleDrawerToggle(event);
              }}
              sx={{
                width: "100%",
                mb: 0,
              }}
              disableRipple
            >
              <>
                {item.icon}
                <ListItemText
                  sx={{
                    ml: 1,
                    fontWeight: 600,
                    lineHeight: 0,
                    fontSize: "14px",
                    color: "white",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                  disableTypography
                >
                  {item.label}
                </ListItemText>
              </>
            </ListItemButton>
          );
        })}
      </List>
    </>
  );

  return (
    <>
        <MuiDrawer
            container={container}
            variant="temporary"
            open={drawerOpen}  // Change here
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    bgcolor: theme.palette.background.paper,
                },
            }}
        >
        {drawerContent}
      </MuiDrawer>
    </>
  );
};

Drawer.propTypes = {
  user: PropTypes.object,
};

Drawer.defaultProps = {
  user: {},
};

export default Drawer;
