import React, {useState } from "react";
import {Box, Button, InputAdornment, Modal, TextField, useMediaQuery, useTheme} from "@mui/material";
import Image from "./Image";
import { useGetCommonBackgroundQuery } from "../../features/api/publicApiSlice";
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Close as CloseIcon} from "@mui/icons-material";
import DOMPurify from "dompurify";
import * as Constant from "../../features/constant";
import CopiedTooltip from "./CopiedTooltip";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentToken} from "../../features/slices/authSlice";
import {useGetShareLinkQuery} from "../../features/api/userApiSlice";
import {useGetHomePageQuery} from "../../features/api/homeApiSlice";
import {closeModal, openModal} from "../../features/slices/modalSlice";

const PATH = process.env.PUBLIC_URL;

const SpinWheelSideNavBar = () => {
    const theme = useTheme();
    const [showSphinWheelInfo, setShowSphinWheelInfo] = useState(false);
    const [affiliatePopupOpen, setAffiliatePopupOpen] = useState(false);
    const closeAffiliatePopup = () => setAffiliatePopupOpen(false);
    const openAffiliatePopup = () => setAffiliatePopupOpen(true);
    const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isLoggedIn = useSelector(selectCurrentToken);
    const dispatch = useDispatch();

    const {
        data: commonBackground,
        isLoading: isCommonBackgroundLoading,
        isSuccess: isCommonBackgroundSuccess,
    } = useGetCommonBackgroundQuery({ prefetch: true });

    const {
        data: shareLink,
        isLoading: isShareLinkLoading,
        isError: isShareLinkError,
        isSuccess: isShareLinkSuccess,
        error: shareLinkError,
    } = useGetShareLinkQuery();

    const {
        data: homePage,
        isFetching: isHomePageFetching,
        isLoading: isHomePageLoading,
        isError: isHomePagetError,
        isSuccess: isHomePageSuccess,
        error: homePageError,
    } = useGetHomePageQuery({ prefetch: true });

    return (
        <Box
            component="div"
            sx={{
                position: "fixed",
                zIndex: "99",
                top: "80%",
                left: "0", // Aligning to the left
                transition: "0.3s",
                height: "70px",
                width: "20px", // Adjust the width to accommodate both boxes
                boxShadow: "1px 1px #00000020",
                color: "black",
                background: `linear-gradient(180deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`,
                borderRadius: "0 6px 6px 0" // Adjust the border radius accordingly
            }}
        >
            <IconButton
                sx={{ color: "black", width: "20px", height: "100%", padding: "0", zIndex: 1, transition: "0.3s" }} // Add transition property
                onClick={() => setShowSphinWheelInfo(!showSphinWheelInfo)}
            >
                <ChevronRightIcon sx={{ transform: showSphinWheelInfo ? 'rotate(180deg)' : 'rotate(0deg)' }} /> {/* Rotate the icon when toggled */}
            </IconButton>
            <Box
                component="div"
                sx={{
                    position: "absolute", // Change to absolute positioning
                    top: 0,
                    left: showSphinWheelInfo ? "30px" : "0", // Slide in from left when shown
                    textAlign: "center",
                    width: "130px",
                    paddingTop: "90px",
                    transform: "rotate(90deg)",
                    transition: "0.3s", // Add transition property
                    zIndex: 0, // Ensure it's behind the button
                    opacity: showSphinWheelInfo ? 0 : 1, // Hide when contact info is shown
                    pointerEvents: showSphinWheelInfo ? 'auto' : 'none', // Allow click events when visible

                }}
            >
            </Box>
            <Box
                component="div"
                sx={{
                    position: "absolute", // Change to absolute positioning
                    top: 0,
                    left: showSphinWheelInfo ? "20px" : "-130px", // Slide in from left when shown
                    transition: "0.3s", // Add transition property
                    width: "70px",
                    height: "100%", // Adjust height to match the parent's height
                    bgcolor: `rgba(0, 0, 0, 0.5)`, // Transparent black background
                    boxShadow: "1px 1px #00000020",
                    zIndex: 0, // Ensure it's behind the button
                    alignContent:'center',
                    border: showSphinWheelInfo ? "2px solid " + theme.palette.secondary.main : "none", // Add border styling
                    borderRadius:'5px'
                }}
            >
                {/* content goes here */}
                <IconButton onClick={() =>
                    dispatch(
                    openModal({
                        title: t("home.luckydraw"),
                        content: (
                            <>
                                <div
                                    style={{paddingBottom: "30px"}}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            homePage?.result?.affiliate_content
                                        ),
                                    }}
                                ></div>
                               {/* {isLoggedIn && (
                                    <div style={{ display: 'flex', justifyContent: 'center',
                                        position: "absolute", bottom: 0, width: "100%", padding: "20px",
                                        background: theme.palette.background.paper, left: 0 }}>
                                        <TextField
                                            label={t("walletcard.myaccountpage.referrallink")}
                                            value={shareLink?.result?.url}
                                            InputLabelProps={{ shrink: true }}
                                            disabled
                                            type="text"
                                            size="small"
                                            sx={{ width: "400px" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disableRipple
                                                            sx={{
                                                                "&.MuiButtonBase-root:hover": {
                                                                    backgroundColor: "transparent",
                                                                },
                                                            }}
                                                        >
                                                            <CopiedTooltip text={shareLink?.result?.url} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                )}*/}
                            </>
                        ),
                        button: !isLoggedIn
                            ? [
                                {
                                    label: t("promotion.login"),
                                    onSubmit: () => {
                                        dispatch(closeModal());
                                        if (mobileView) {
                                            navigate(Constant.getPath("/login"));
                                        } else {
                                            dispatch(
                                                openModal({
                                                    title: t("dialog.proceedtologin"),
                                                    button: [
                                                        {
                                                            label: t("dialog.login"),
                                                            onSubmit: () =>
                                                                dispatch(closeModal()),
                                                        },
                                                    ],
                                                })
                                            );
                                        }
                                    },
                                },
                                {
                                    label: t("promotion.register"),
                                    onSubmit: () => {
                                        dispatch(closeModal());
                                        navigate(Constant.getPath("/register"));
                                    },
                                },
                            ]
                            : [],
                    })
                )}>
                    <Image
                        height="50px"
                        width="50px"
                        src={process.env.REACT_APP_LUCKY_SPIN}
                    />
                </IconButton>
            </Box>
          {/*  <Modal open={affiliatePopupOpen} onClose={closeAffiliatePopup}>
                <Box
                    sx={{
                        minWidth: "350px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: mobileView ? "100vw" : "calc(50vw - 10px)",
                        height: mobileView ? "100vh" : "auto",
                        transform: "translate(-50%, -50%)",
                        outline: "none",
                        color: "white",
                        padding: "5px 20px",
                        textAlign: "left",
                        background: theme.palette.background.paper,
                        overflowY: mobileView ? "auto" : "hidden",
                        scrollbarWidth: "none",
                        "-ms-overflow-style": "none",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                    textAlign="right"
                >
                    <IconButton sx={{ position: "absolute", top: 0, right: 0 }} edge="start" onClick={closeAffiliatePopup}>
                        <CloseIcon />
                    </IconButton>
                    <div
                        style={{
                            maxHeight: mobileView ? "100vh" : "80vh",
                            overflowY: "auto",
                            scrollbarWidth: "none",
                            "-ms-overflow-style": "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        <div dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                homePage?.result?.affiliate_content
                            ),
                        }}
                        ></div>
                    </div>

                    {!isLoggedIn &&
                        <Box sx={{marginTop: "15px", textAlign: "right"}}>
                            <Button
                                variant="contained"
                                sx={{
                                    mr: 1,
                                    textAlign: "right"
                                }}
                                onClick={() => mobileView ? navigate(Constant.getPath("/login")) : closeAffiliatePopup()}
                            >
                                {t("header.login")}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => navigate(Constant.getPath("/register"))}
                            >
                                {t("header.signup")}
                            </Button>
                        </Box>
                    }

                    {isLoggedIn && <p>
            <TextField
                label={t("walletcard.myaccountpage.referrallink")}
                value={shareLink?.result?.url}
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                sx={{width: "300px"}}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                sx={{
                                    "&.MuiButtonBase-root:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <CopiedTooltip text={shareLink?.result?.url} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </p>}
                </Box>
            </Modal>*/}
        </Box>
    );
};

export default SpinWheelSideNavBar;
